import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml, Container } from 'layout';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import Icon from 'common/IconCustom';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { ProductsDifferencesProps } from '@shared/types/umbraco/compositions';

import './ProductsDifferences.scss';

const ProductsDifferences: FC<ProductsDifferencesProps.IProductsDifferencesProps> = ({
  productsDifferencesData: {
    differences: { productsDifferencesTitle, productsDifferencesTables, productsDifferencesLink },
  },
}) => {
  const { isDesktop } = useScreenRecognition();

  return (
    <section className="products-differences" data-testid="products-differences">
      <Container fluid>
        <DangerouslySetInnerHtml
          html={productsDifferencesTitle}
          element="div"
          className="products-differences__header"
        />

        <div className="products-differences__table-scroll">
          <div className="products-differences__table">
            {productsDifferencesTables?.[0].properties.productsDifferencesTitles?.length ? (
              <div className="products-differences__titles">
                {productsDifferencesTables?.[0].properties.productsDifferencesTitles.map(
                  ({ properties: { icon, imageIcon, imageAlt, description } }) => (
                    <div key={imageAlt || icon[0]} className="products-differences__title">
                      {imageIcon && imageIcon.fallbackUrl ? (
                        <GatsbyImage
                          image={imageIcon}
                          alt={imageAlt}
                          objectFit="contain"
                          className="products-differences__title-icon"
                        />
                      ) : (
                        <Icon icon={icon?.[0]} />
                      )}

                      {description ? (
                        <DangerouslySetInnerHtml
                          className="products-differences__title-description"
                          html={description}
                        />
                      ) : null}
                    </div>
                  )
                )}
              </div>
            ) : null}

            {productsDifferencesTables?.[0].properties.productsDifferencesColumn?.length ? (
              <div className="products-differences__columns">
                {productsDifferencesTables?.[0].properties.productsDifferencesColumn.map(
                  ({
                    properties: {
                      productsDifferencesActives,
                      productsDifferencesImage,
                      startGradient,
                      endGradient,
                    },
                  }) => (
                    <div
                      key={productsDifferencesImage[0].properties.image.fallbackUrl}
                      className="products-differences__column"
                      style={{
                        backgroundImage: startGradient?.value
                          ? `linear-gradient(to left, #${startGradient.value}, #${endGradient.value})`
                          : null,
                      }}
                    >
                      {isDesktop ? (
                        <div className="products-differences__column-image-box">
                          {productsDifferencesImage?.length ? (
                            <GatsbyImage
                              image={productsDifferencesImage[0].properties.image}
                              alt={productsDifferencesImage[0].properties.imageAlt}
                              objectFit="contain"
                              className="products-differences__column-image"
                            />
                          ) : null}
                        </div>
                      ) : null}

                      {Array.from({ length: productsDifferencesActives }, (_, i: number) => (
                        <DangerouslySetInnerHtml
                          className={classNames(
                            'products-differences__column-icon',
                            `products-differences__column-icon--${startGradient.label}`
                          )}
                          key={
                            productsDifferencesTables?.[0].properties.productsDifferencesTitles?.[i]
                              ?.properties.description
                          }
                          html={
                            productsDifferencesTables?.[0].properties.productsDifferencesTitles?.[i]
                              ?.properties.description
                          }
                        />
                      ))}
                    </div>
                  )
                )}
              </div>
            ) : null}

            {!isDesktop &&
            productsDifferencesTables?.[0].properties.productsDifferencesColumn?.length ? (
              <div className="products-differences__packshots">
                {productsDifferencesTables?.[0].properties.productsDifferencesColumn.map(
                  ({ properties: { productsDifferencesImage, startGradient, endGradient } }) =>
                    productsDifferencesImage?.length ? (
                      <div
                        className="products-differences__packshot"
                        style={{
                          backgroundImage: startGradient?.value
                            ? `linear-gradient(to left, #${startGradient.value}, #${endGradient.value})`
                            : null,
                        }}
                      >
                        <GatsbyImage
                          image={productsDifferencesImage[0].properties.image}
                          alt={productsDifferencesImage[0].properties.imageAlt}
                          objectFit="contain"
                          className="products-differences__packshot-image"
                        />
                      </div>
                    ) : null
                )}
              </div>
            ) : null}
          </div>
        </div>

        {productsDifferencesLink?.length ? (
          <Button
            variant="white"
            classes="products-differences__link"
            to={productsDifferencesLink?.[0].properties.link[0].url}
            ariaLabel={productsDifferencesLink?.[0].properties.ariaLabel}
          >
            {productsDifferencesLink?.[0].properties.link[0].name}
          </Button>
        ) : null}
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment FragmentProductsDifferences on TProductsDifferences {
    structure
    properties {
      productsDifferencesData {
        differences {
          productsDifferencesTitle
          productsDifferencesLink {
            properties {
              ariaLabel
              link {
                name
                target
                url
              }
            }
          }
          productsDifferencesTables {
            properties {
              productsDifferencesTitles {
                properties {
                  description
                  icon
                  iconAlignment
                  imageIcon {
                    ...FragmentGatsbyProps
                    gatsbyImage {
                      childImageSharp {
                        fixed(height: 36) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
                    }
                  }
                  imageAlt
                  iconAlignment
                }
              }
              productsDifferencesColumn {
                properties {
                  productsDifferencesImage {
                    properties {
                      image {
                        ...FragmentGatsbyProps
                        gatsbyImage {
                          childImageSharp {
                            fluid(maxWidth: 132) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                      imageAlt
                    }
                  }
                  productsDifferencesActives
                  startGradient {
                    value
                    label
                  }
                  endGradient {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ProductsDifferences;
