import React, { FC, useCallback } from 'react';
import { graphql } from 'gatsby';
import { useBazaarVoice } from '@phx-husky/use-bazaarvoice';
import { ProductListingPageTypes } from '@shared/types/umbraco/content/productListingPage';

import Layout from 'gatsby-theme-husky/src/layout/Layout';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import SimpleBanner from 'gatsby-theme-husky/src/components/SimpleBanner';
import ProductCardListWithFilters from 'components/ProductCardList';
import TextSection from 'components/TextSection';
import SectionWithCarousel from 'components/SectionWithCarousel';
import QuestionsCarousel from 'components/SupportPage/QuestionsCarousel';
import QuestionsBlock from 'components/SupportPage/QuestionsBlock';
import WideBanner from 'components/WideBanner';
import InfoBanner from 'components/InfoBanner';
import SignUp from 'components/SignUp';
import SlimBanner from 'components/SlimBanner';
import DescriptionLinkVideo from 'common/DescriptionLinkVideo';
import ProductFilters from 'components/ProductFilters';
import ProductsDifferences from 'components/ProductsDifferences';

import { BV_SCRIPT_HOST } from 'utils/constants';
import { gtmService } from 'utils/gtmService';
import { ProductItemType } from 'utils/gtmService/model.d';

const elements = {
  'Text Section': ({ properties }, keyId) => <TextSection key={keyId} {...properties} />,
  'Description Link Video': ({ properties }, keyId) => (
    <DescriptionLinkVideo key={keyId} {...properties} />
  ),
  'Info Banner Wide': ({ properties }, keyId) => <WideBanner key={keyId} {...properties} />,
  'Slim banner': ({ properties }, keyId) => <SlimBanner key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Product Listing': ({ properties }, keyId) => (
    <ProductCardListWithFilters key={keyId} {...{ ...properties }} />
  ),
  'Products Differences': ({ properties }, keyId) => (
    <ProductsDifferences key={keyId} {...properties} />
  ),
  'Section with carousel': ({ properties }, keyId) => {
    const { text, card, color, carouselMode, button, waiSettings } = properties;
    const questionCards = card.map((item) => item.properties);

    return (
      <SectionWithCarousel
        key={keyId}
        text={text}
        color={color}
        cardsBlock={
          carouselMode ? (
            <QuestionsCarousel
              questionCards={questionCards}
              ariaNext={waiSettings?.ariaNext}
              ariaPrev={waiSettings?.ariaPrev}
            />
          ) : (
            <QuestionsBlock questionCards={questionCards} />
          )
        }
        button={button}
      />
    );
  },
  'Product Filters': ({ properties }, keyId) => <ProductFilters key={keyId} {...properties} />,
};

const ProductListingPage: FC<ProductListingPageTypes.IProperties> = ({
  pageContext: { breadCrumbs, link, searchUrl, pageName },
  data: {
    page: {
      nodes: [
        {
          banner,
          defaultCompositions,
          body,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          alternateUrls,
          ogImageUrl,
        },
      ],
    },
    products,
  },
}) => {
  const { productListingSettings, waiSettings, singupFormBaner, siteSettings } =
    defaultCompositions;
  const imageUrl = ogImageUrl || banner?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = banner?.[0]?.properties?.title;
  const { isShopify, lang, usePriceSpider, bvScript, isEanProductId } = siteSettings || {};

  if (bvScript) {
    const bvScriptUrl = `${BV_SCRIPT_HOST}/${bvScript}/bv.js`;

    useBazaarVoice(bvScriptUrl);
  }

  const emitProductListingViewGtmEvent = useCallback((sortedProducts: ProductItemType[]) => {
    gtmService.emitProductListingView(pageName, sortedProducts);
  }, []);

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        imageUrl,
        imageAlt,
      }}
    >
      {banner?.length ? <SimpleBanner {...{ ...banner[0].properties, breadCrumbs }} /> : null}
      {body.length ? (
        <div className="product-listing-page__body">
          <BodyRenderer
            bodyData={body}
            bodyStructure={elements}
            bodyItemProps={{
              ...{
                products,
                productListingSettings,
                waiSettings,
                singupFormBaner,
                isShopify,
                lang,
                usePriceSpider,
                isEanProductId,
                emitProductListingViewGtmEvent,
              },
            }}
          />
        </div>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query allUmbracoProductListingQuery($productLinks: [String], $link: String = "") {
    page: allUmbracoProductListing(filter: { link: { eq: $link } }) {
      nodes {
        useRedirects
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        defaultCompositions {
          siteSettings {
            lang
            isShopify
          }
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          productListingSettings {
            showing
            titlePlaceholder
          }
          waiSettings {
            skipText
            ariaPrev
            ariaNext
            ariaToggle
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          purchaseSettings {
            ...FragmentPurchaseSettings
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
        }
        banner {
          ...FragmentSimpleBanner
        }
        body {
          ... on TTextSection {
            ...FragmentTextSection
          }
          ... on TDescriptionLinkVideo {
            ...FragmentDescriptionLinkVideo
          }
          ... on TSectionwithcarousel {
            ...FragmentSectionWithCarousel
          }
          ... on TProductListing {
            ...FragmentProductCardList
          }
          ... on TInfoBannerWide {
            ...FragmentWideBanner
          }
          ... on TInfoBanner {
            ...FragmentInfoBanner
          }
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
          ... on TSlimbanner {
            ...FragmentSlimBanner
          }
          ... on TProductFilters {
            ...FragmentProductFilters
          }
          ... on TProductsDifferences {
            ...FragmentProductsDifferences
          }
        }
      }
    }
    products: allProduct(filter: { link: { in: $productLinks } }) {
      nodes {
        useRedirects
        position
        link
        ean
        skuId
        productVariantsShopifyData {
          skuId
          shopifyId
        }
        tags {
          isFamily
          isLabel
          title
          color {
            label
          }
        }
        cardLink {
          url
        }
        title
        productSize
        productScent
        productType
        shortDescription
        cardDescription
        lang
        images {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
        cardImage {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ProductListingPage;
